import { API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
const CashbookModel = {
  getCashbook(date) {
    return axios.get(`${API_ROOT}/cashbook/entry/${date}`)
  },
  getCashbookCronStatus() {
    return axios.get(`${API_ROOT}/cashbook/cashbookCronRun`)
  }

}
export default { CashbookModel };